import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  Avatar,
  FormControl,
  Box,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useField } from 'formik';

const CustomSelect = ({
  options,
  name,
  defaultOptionLabel = 'Select an option',
  className = '',
  avatarSize = 24,
  selectStyles = {},
  disabled = false,
  placeholderClass = '',
}) => {
  const [field, meta, helpers] = useField(name);

  // Function to extract initials from the label
  const getInitials = (label) => {
    if (!label) return '';
    const words = label.split(' ');
    const initials =
      words.length > 1
        ? `${words[0][0]}${words[1][0]}`
        : `${words[0][0]}${words[0][1] || ''}`;
    return initials.toUpperCase();
  };
  const getDynamicColor = (label) => {
    const colors = [
      '#FF5733',
      '#33FF57',
      '#3357FF',
      '#F333FF',
      '#FFC133',
      '#33FFF5',
      '#8A33FF',
      '#FF3360',
      '#33FF8A',
      '#FF8A33',
    ];
    const hash = label
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };
  const handleChange = (event) => {
    const { value } = event.target;
    helpers.setValue(value); // Update Formik value
  };

  const baseSelectStyles = {
    height: '40px',
    borderRadius: '12px',
    ...selectStyles,
  };

  return (
    <FormControl fullWidth className={className}>
      <Select
        value={field.value}
        onChange={handleChange}
        disabled={disabled}
        IconComponent={KeyboardArrowDownIcon}
        sx={baseSelectStyles}
        className={`form-control shadow-none ${placeholderClass} ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography sx={{ color: 'text.secondary' }}>
                {defaultOptionLabel}
              </Typography>
            );
          }
          const selectedOption = options.find((opt) => opt.value === selected);
          if (!selectedOption) return null;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {selectedOption.avatar ? (
                <Avatar
                  src={selectedOption.avatar}
                  sx={{ width: avatarSize, height: avatarSize }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: avatarSize,
                    height: avatarSize,
                    fontSize: `${avatarSize * 0.5}px`,
                    bgcolor: getDynamicColor(selectedOption.label),
                  }}
                >
                  {getInitials(selectedOption.label)}
                </Avatar>
              )}
              <Typography>{selectedOption.label}</Typography>
            </Box>
          );
        }}
      >
        <MenuItem value="">
          <Typography sx={{ color: 'text.secondary' }}>
            {defaultOptionLabel}
          </Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            {option.avatar ? (
              <Avatar
                src={option.avatar}
                sx={{
                  width: avatarSize,
                  height: avatarSize,
                  fontSize: `${avatarSize}px`,
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: avatarSize,
                  height: avatarSize,
                  fontSize: `${avatarSize * 0.5}px`,
                  bgcolor: getDynamicColor(option.label),
                }}
              >
                {getInitials(option.label)}
              </Avatar>
            )}
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <Typography variant="caption" color="error">
          {meta.error}
        </Typography>
      )}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      avatar: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  defaultOptionLabel: PropTypes.string,
  className: PropTypes.string,
  avatarSize: PropTypes.number,
  selectStyles: PropTypes.object,
  disabled: PropTypes.bool,
  placeholderClass: PropTypes.string,
};

export default CustomSelect;
