import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { TextField } from '../../../../components/forms';
import { Formik } from 'formik';
import { constants, Utils } from '../../../../helpers';
// TODO : use later once have the aPI
// import axios from 'axios';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';
import DeviceLocationAreas from '../../DeviceLocationAreas';
import timezones from '../../../../data/support/timezone.json';
import './DeviceInfoBlock.scss';
import { useTranslation } from 'react-i18next';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import * as Yup from 'yup';

const DeviceInfoBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceInfos,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  moveDeviceDetailCallback,
  updateStore,
  accountId,
  uuid,
  devicePublishId,
  updateFirmware,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [deviceName, setDeviceName] = useState(deviceInfos?.deviceName);
  const [slectedAreaLocation, setslectedAreaLocation] = useState({});
  const zoneTimeList = timezones?.data;
  const timezone = deviceInfos?.properties?.timezone;
  const [currentUserTimezone, setCurrentUserTimezone] = useState(timezone);
  const [currentTime, setCurrentTime] = useState(
    moment
      .tz(
        moment(),
        timezone
          ? findTimeZoneFromSelectedValue(timezone)?.location
          : moment.tz.guess(),
      )
      .format('MMM DD, YYYY hh:mm:ss a'),
  );
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const resourceList = Utils.getDeviceSettingResource(0);
  const capbilityProperty = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0],
  );

  useEffect(() => {
    if (currentUserTimezone) {
      let secTimer = setInterval(() => {
        setCurrentTime(
          moment
            .tz(
              moment(),
              findTimeZoneFromSelectedValue(currentUserTimezone)?.location,
            )
            .format('MMM DD, YYYY hh:mm:ss a'),
        );
      }, 1000);
      return () => clearInterval(secTimer);
    }
  }, [currentUserTimezone]);

  useEffect(() => {
    setCurrentUserTimezone(timezone);
  }, [deviceInfos]);

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  useEffect(() => {
    moveDeviceLocation(slectedAreaLocation);
  }, [deviceName]);

  const moveDeviceLocation = async (slectedAreaLocation) => {
    try {
      const reqBody = {
        oldLocationId: deviceInfos?.locationId,
        oldAreaId: deviceInfos?.areaId,
        olddeviceName: deviceInfos?.deviceName,
        newLocationId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.locationId
            : slectedAreaLocation?.locationId,
        newAreaId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.areaId
            : slectedAreaLocation.areaId,
        deviceName: deviceName ? deviceName : deviceInfos?.deviceName,
        orgId: deviceInfos?.orgId,
        deviceId: deviceInfos?.deviceId,
      };
      moveDeviceDetailCallback(reqBody);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const validateAuthenticated = Yup.object({
    deviceName: Yup.string().max(40, constants.DEVICE_NAME_MAX_LENGTH_MESSAGE),
  });

  const onDeviceNameChange = (e, setFieldValue) => {
    if (e?.target?.value?.length <= 40) {
      setDeviceName(e.target.value);
      setFieldValue('deviceName', e.target.value);
    } else {
      setFieldValue('deviceName', e.target.value);
      setDeviceName(deviceInfos?.deviceName);
    }
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      {showMsg && (
        <Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
          <div className="d-flex">
            <div>
              <HiOutlineExclamationCircle
                size={20}
                stroke={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_24')}
              />
            </div>
            <div className="text-alignment">
              {!userMsg ? (
                <p>{userMsg}</p>
              ) : (
                <>
                  <Alert.Heading>
                    {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                  </Alert.Heading>
                  <p>{constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}</p>
                </>
              )}
            </div>
          </div>
        </Alert>
      )}
      {deviceInfos && (
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            deviceName: deviceInfos?.deviceName,
            volume: 2,
            sensitivity: 2,
            deviceDegrees: '',
            noiseReduction: false,
            audioOutVolume: 2,
            areaData: deviceInfos?.areaId,
            timezone: findTimeZoneFromSelectedValue(
              currentUserTimezone ? currentUserTimezone : timezone,
            )?.location,
          }}
          validationSchema={validateAuthenticated}
          onSubmit={(values, { setSubmitting }) => {
            // TODO : Delete later
            Utils.vmsLogger().log(values);
            setSubmitting(true);
            setVariant('danger');
            setUserMsg('');
            setSubmitting(false);
            saveAudioSettingsCallback(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange }) => (
            <Form className="form device-info-form" onSubmit={handleSubmit}>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2 mt-4">
                    <label>{constants.DEVICE_INFO_DEVICE_NAME_TITLE}</label>
                  </div>
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.DEVICE_INFO_MEETING_ROOM_TITLE}
                    name="deviceName"
                    value={values.deviceName}
                    type="text"
                    onChange={(e) => onDeviceNameChange(e, setFieldValue)}
                  />
                </Col>
              </Row>
              <div className="tab-title device-info-title">
                <label>{constants.DEVICES_TAB_DEVICE_INFO}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {constants.DEVICE_INFO_DEVICE_STATUS_TITLE}
                    </div>
                    <div className="settings-info-block-content mb-3">
                      {curDeviceStatus ===
                      constants.DEVICES_RETURN_ONLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-online" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_OFFLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-offline" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
                        <FaCircle
                          size={10}
                          className="status-icon-deactivated"
                        />
                      ) : (
                        <FaCircle size={10} className="status-icon-entered" />
                      )}
                      {curDeviceStatus}
                    </div>
                    {curDeviceStatus ===
                      constants.DEVICES_RETURN_OFFLINE_STATUS && (
                      <Alert variant="danger">
                        <div className="d-flex">
                          <div>
                            <HiOutlineExclamationCircle
                              size={20}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                            />
                          </div>
                          <div className="text-alignment">
                            {userMsg !== '' ? (
                              <p>{userMsg}</p>
                            ) : (
                              <>
                                {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                                <p>
                                  {constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </Alert>
                    )}
                  </Col>
                </Row>
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'serial-number',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_SERIAL_NO_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.serialNo}
                      </div>
                    </Col>
                  </Row>
                )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'mac-address',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.macAddress}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {constants.DEVICE_INFO_FIRMWARE_TITLE}
                    </div>

                    <div className="settings-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {deviceInfos?.properties?.['firmware-version']
                        ? deviceInfos?.properties?.['firmware-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {t('CHANNELS_INFO_FIRMWARE_TITLE')}
                    </div>
                    <div className="settings-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {deviceInfos?.properties?.['edge-app-version']
                        ? deviceInfos?.properties?.['edge-app-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                {deviceInfos?.properties?.['avlbl-duclo-ver'] &&
                  deviceInfos?.properties?.['edge-app-version'] !=
                    deviceInfos?.properties?.['avlbl-duclo-ver'] && (
                    <Row className="settings-block">
                      <Col>
                        <div
                          className="alert alert-danger info-update"
                          role="alert"
                        >
                          <div className="alert-message">
                            <HiOutlineExclamationCircle
                              size={15}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                              className="alert-icon"
                            />
                          </div>
                          <p>
                            {' '}
                            {constants.UPDATE_AVAILABLE_TITLE}{' '}
                            <span
                              onClick={() => updateFirmware()}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--error_24'),
                              }}
                            >
                              {constants.HERE_LABEL}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'manufacturer',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_MANUFACTURER_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {Utils.getManufacturer(deviceInfos?.manufacturer)}
                      </div>
                    </Col>
                  </Row>
                )}

                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'model',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_MODEL_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.modelNumber}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <Row className="settings-block mt-4">
                <Col>
                  <div className="device-infodate-time-title device-location">
                    {constants.DEVICE_INFO_ASSIGN_LOCATIONS_TITLE}
                  </div>
                  <DeviceLocationAreas
                    defaultLocaionsId={deviceInfos?.locationId}
                    defaultLocaionsData={values.areaData}
                    updateLocationData={(selectedLocationArea) => {
                      setslectedAreaLocation(selectedLocationArea);
                      moveDeviceLocation(selectedLocationArea);
                      setFieldValue('areaData', selectedLocationArea.areaId);
                    }}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default DeviceInfoBlock;
