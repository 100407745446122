import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LazyLoadComponent,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import { BiGroup } from 'react-icons/bi';
import {
  HiOutlineAdjustments,
  HiOutlineCalendar,
  HiOutlineExclamationCircle,
  HiOutlineFolderOpen,
} from 'react-icons/hi';
import { GoPlus } from 'react-icons/go';
import { PiFolderOpenBold } from 'react-icons/pi';
import { TbCloudDownload } from 'react-icons/tb';
import Box from '@mui/material/Box';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import axios from 'axios';
import moment from 'moment';

import {
  ContextMenu,
  Header,
  ListTable,
  LocationSelector,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  SiteToast,
  TextBlock,
} from '../../components/common';
import { AppDefaults, constants, Utils } from '../../helpers/';
import ManageTags from './modalForms/ManageTags';
import NewIncident from './modalForms/NewIncident';
import incidentStatusMap from './shared/IncidentStatusMap';
import StatusFilters from './shared/StatusFilters';

import { usePoliciesStore } from '../../store/policiesStore';
import { useOrganizations } from '../../store/OrganizationsStore';

import MOCKED_INCIDENTS from '../../data/mocks/incidents.json';

import './ManageIncidents.scss';
import { LegendToggleOutlined } from '@mui/icons-material';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { useSelector } from 'react-redux';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const USE_MOCKED_DATA = false;

const localConstants = {
  INCIDENT_STATUS: 'STATUS',
};

const gridFields = [
  {
    field: 'summary',
    sortableField: 'sortable_summary',
    fieldType: 'text',
    displayName: constants.INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME,
  },
  {
    field: 'incidentId',
    sortableField: 'sortable_incidentId',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_INCIDENT_ID,
  },
  {
    field: 'tags',
    sortableField: 'sortable_tags',
    fieldType: 'text',
    displayName: constants.INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_TAGS,
  },
  {
    field: 'incidentDateTime',
    sortableField: 'sortable_incidentDateTime',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_INCIDENT_DATE,
  },
  {
    field: 'status',
    sortableField: 'sortable_status',
    fieldType: 'text',
    displayName: constants.INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS,
  },
  {
    field: 'action',
    sortableField: null,
    fieldType: 'icon',
    clickHandler: () => {
      Utils.vmsLogger().log('Filter clicked');
    },
    displayContent: (
      <HiOutlineAdjustments className="filter-action" size={1.5} />
    ),
  },
];

const getColDefs = () => {
  const maxWidthMap = {
    summary: 250,
    incidentId: 230,
    tags: 250,
    incidentDateTime: 120,
    status: 130,
    action: 54,
  };

  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    sortableField: gridField.sortableField,
    displayName:
      gridField.fieldType === 'text'
        ? gridField.displayName
        : gridField.displayContent,
    cellMaxWidth: maxWidthMap[gridField.field],
  }));
};

const defaultFormData = {
  summary: '',
  description: '',
  locationId: '',
  areaId: '',
  timezone: '',
  incidentDateTime: 0,
  tags: [],
};

const defaultStatusFilters = [
  {
    key: 'open',
    show: true,
    displayText: constants.INCIDENTS_MODAL_STATUS_FILTER_OPEN_LABEL,
  },
  {
    key: 'closed',
    show: true,
    displayText: constants.INCIDENTS_MODAL_STATUS_FILTER_CLOSED_LABEL,
  },
];

const ManageIncidents = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [columnDefs, setColumnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState(null);
  const [hasData, setHasData] = useState(false);
  const [statusFilters, setStatusFilters] = useState([]);
  const [rawIncidents, setRawIncidents] = useState([]);
  const [baselineIncidents, setBaselineIncidents] = useState([]);
  const [filteredIncidents, setFilteredIncidents] = useState([]);
  const [numOfIncidents, setNumOfIncidents] = useState(0);
  const [tags, setTags] = useState([]);
  const [shouldSaveTags, setShouldSaveTags] = useState(false);
  const [hasViewEditAccess, setHasViewEditAccess] = useState(false);
  const [showFilterIncidentsModal, setShowFilterIncidentsModal] =
    useState(false);
  const [showNewIncidentModal, setShowNewIncidentModal] = useState(false);
  const [showManageTagsModal, setShowManageTagsModal] = useState(false);
  const [areTagsModified, setAreTagsModified] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [isNewIncidentFormComplete, setIsNewIncidentFormComplete] =
    useState(false);
  const [submitNewIncidentForm, setSubmitNewIncidentForm] = useState(false);
  const [showManageTagsToast, setShowManageTagsToast] = useState(false);
  const [manageTagsUserMsg, setManageTagsUserMsg] = useState('');

  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );

  const currentUserPolicies = getLoggedInUserPolicies();
  // const { getCustomerOrgData } = useOrganizations();
  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();
  const navigate = useNavigate();

  let currentTags;

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    setStatusFilters([...defaultStatusFilters]);
  }, []);

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const currentColDefs = [...columnDefs];

    currentColDefs.every((colDef) => {
      if (colDef?.field?.toUpperCase() === 'ACTION') {
        colDef.clickHandler = () => {
          setShowFilterIncidentsModal(true);
        };
        return false;
      }
      return true;
    });

    setColumnDefs([...currentColDefs]);
    setHasViewEditAccess(
      currentUserPolicies?.update_incident_status ||
        currentUserPolicies?.view_created_incident ||
        currentUserPolicies?.view_shared_incident,
    );

    if (orgDetails?.orgId) {
      fetchData();
    }
  }, [orgDetails?.orgId]);

  const menuItems = [
    {
      icon: <HiOutlineExclamationCircle size="1.25em" />,
      text: constants.INCIDENTS_PAGE_CONTEXT_MENU_DETAILS_LABEL,
      clickHandler: (e, data, i) => {
        navigate(
          `/incidents/details.html?incidentId=${data?.incidentId}&activeTab=${i}`,
        );
      },
    },
    {
      icon: <PiFolderOpenBold size="1.25em" />,
      text: constants.INCIDENTS_PAGE_CONTEXT_MENU_EVIDENCE_LABEL,
      clickHandler: (e, data, i) => {
        navigate(
          `/incidents/details.html?incidentId=${data?.incidentId}&activeTab=${i}`,
        );
      },
    },
    {
      icon: <BiGroup size="1.25em" />,
      text: constants.INCIDENTS_PAGE_CONTEXT_MENU_CONTRIBUTORS_LABEL,
      clickHandler: (e, data, i) => {
        navigate(
          `/incidents/details.html?incidentId=${data?.incidentId}&activeTab=${i}`,
        );
      },
    },
    {
      icon: <TbCloudDownload size="1.25em" />,
      text: constants.INCIDENTS_PAGE_CONTEXT_MENU_EXPORT_LABEL,
      clickHandler: (e, data, i) => {
        navigate(
          `/incidents/details.html?incidentId=${data?.incidentId}&activeTab=${i}`,
        );
      },
    },
  ];

  const applyStatusFilters = () => {
    if (!Array.isArray(filteredIncidents) || filteredIncidents.length < 1)
      return;

    const currentIncidents = filteredIncidents.filter((incident) => {
      let filteredStatus = statusFilters.find((status) => {
        return (
          status?.key?.toUpperCase() === incident?.status ||
          (status?.key?.toUpperCase() === 'OPEN' &&
            incident?.status?.toUpperCase() === 'REOPENED')
        );
      });

      if (filteredStatus?.show === true) {
        return incident;
      }
    });

    setNumOfIncidents(currentIncidents.length);
    setBaselineIncidents([...currentIncidents]);

    setRowData([...getRowData(currentIncidents)]);
    setHasData(true);
  };

  // Refetch all incidents
  const refreshIncidents = (newIncident) => {
    if (newIncident) {
      fetchIncidents();
    }
  };

  const filterByStatus = (incidents) => {
    const openIncidents = [];
    const reopenedIncidents = [];
    const closedIncidents = [];

    incidents.forEach((incident) => {
      switch (incident.status) {
        case 'OPEN':
          openIncidents.push(incident);
          break;
        case 'REOPENED':
          reopenedIncidents.push(incident);
          break;
        case 'CLOSED':
          closedIncidents.push(incident);
          break;
        default:
        // nothing
      }
    });
    return openIncidents.concat(reopenedIncidents, closedIncidents);
  };

  // Get all Incident Management Tags
  const fetchCurrentTags = async () => {
    try {
      const res = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/tags?category=IM`,
        Utils.requestHeader(),
      );
      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        currentTags = responseData?.data?.tags;

        if (Array.isArray(currentTags) && currentTags.length > 0) {
          setTags([...currentTags]);
        } else {
          setTags([]);
        }
      } else {
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  // Get Incidents
  const fetchIncidents = async () => {
    try {
      let incidents, tableRowData;

      if (USE_MOCKED_DATA) {
        incidents = [...MOCKED_INCIDENTS.data.incidents];
        if (incidents.length > 0) {
          setHasData(true);
        }
      } else {
        const res = await axios.get(
          `/incident/orgs/${orgDetails?.orgId}/incidents`,
          Utils.requestHeader(),
        );
        const responseData = res?.data;

        // Set incidents if request is successful
        if (responseData?.meta?.code === 200) {
          incidents = responseData?.data?.incidents;

          incidents?.forEach((incident) => {
            const convertedDateAccToTimeZone = moment
              .tz(incident.incidentDateTime, incident.timezone)
              .format('YYYY/MM/DD hh:mm:ss A');
            const momentObj = moment.tz(
              convertedDateAccToTimeZone,
              incident.timezone,
            );
            const convertToMilliseconds = momentObj.valueOf();
            incident.incidentDateTime = convertToMilliseconds;
          });

          if (Array.isArray(incidents) && incidents.length > 0) {
            const sortedIncidents = incidents.sort((a, b) => {
              return b.incidentDateTime - a.incidentDateTime;
            });
            const filteredByStatus = filterByStatus(sortedIncidents);
            setRawIncidents([...filteredByStatus]);
            setBaselineIncidents([...filteredByStatus]);
            setFilteredIncidents([...filteredByStatus]);
            setNumOfIncidents(filteredByStatus.length);

            tableRowData = Array.isArray(filteredByStatus)
              ? getRowData(filteredByStatus)
              : [];

            await new Promise((resolve) => {
              setRowData([...tableRowData]);
              resolve();
            });
            setHasData(true);
          }
        } else {
          if (res?.code) {
            setUserMsg(`${res.code}: ${res.message}`);
          } else if (responseData?.data) {
            setUserMsg(responseData?.data?.userMsg);
          }
          setShowToast(true);
        }
      }
    } catch (error) {
      setHasData(false);
      setUserMsg(error);
      setShowToast(true);
    }
  };

  const fetchData = async () => {
    try {
      setShowLoader(true);
      await fetchCurrentTags();
      await fetchIncidents();
      setStatusFilters([...defaultStatusFilters]);
      setShowLoader(false);
    } catch (err) {
      setHasData(false);
      Utils.vmsLogger().error(err);
    }
  };

  const filterIncidentsByLocationId = async (locationId) => {
    let incidentsByLocationId;

    if (!locationId) {
      return;
    }

    if (locationId === AppDefaults.ALL_LOCATIONS_ID) {
      incidentsByLocationId = [...baselineIncidents];
    } else {
      incidentsByLocationId = baselineIncidents.filter(
        (incident) => incident.locationId === locationId,
      );
    }

    if (
      Array.isArray(incidentsByLocationId) &&
      incidentsByLocationId.length > 0
    ) {
      setFilteredIncidents([...incidentsByLocationId]);
      setNumOfIncidents(incidentsByLocationId.length);
    } else {
      setFilteredIncidents([]);
      setNumOfIncidents(0);
    }

    setRowData([...getRowData(incidentsByLocationId)]);
  };

  const statusMap = {
    OPEN: constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_OPEN,
    REOPENED: constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_REOPENED,
    INCOMPLETE: constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_INCOMPLETE,
    CLOSED: constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED,
    CLOSED_RESOLVED:
      constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_RESOLVED,
    CLOSED_UNRESOLVED:
      constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_UNRESOLVED,
  };

  const getRowData = (incidents) => {
    let incidentProps = {};
    const rowData = [];

    if (!Array.isArray(incidents)) return;

    incidents.forEach((incident) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'STATUS':
            if (incident[def?.field].toUpperCase() === 'CLOSED') {
              switch (incident['resolution']?.toUpperCase()) {
                case 'RESOLVED':
                  incidentProps[def?.field] =
                    incidentStatusMap[
                      `${incident[def?.field].toUpperCase()}_RESOLVED`
                    ];
                  incidentProps[def?.sortableField] = `${incident[
                    def?.field
                  ].toUpperCase()}_RESOLVED`;
                  break;

                case 'UNRESOLVED':
                  incidentProps[def?.field] =
                    incidentStatusMap[
                      `${incident[def?.field].toUpperCase()}_UNRESOLVED`
                    ];
                  incidentProps[def?.sortableField] = `${incident[
                    def?.field
                  ].toUpperCase()}_UNRESOLVED`;
                  break;
                default:
                  incidentProps[def?.field] =
                    incidentStatusMap[incident[def?.field].toUpperCase()];
              }
            } else {
              incidentProps[def?.field] =
                incidentStatusMap[incident[def?.field].toUpperCase()];
              incidentProps[def?.sortableField] =
                incident[def?.field].toUpperCase();
            }
            break;

          case 'INCIDENTDATETIME':
            incidentProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in incident
                  ? moment
                      .tz(incident?.incidentDateTime, incident.timezone)
                      .utc()
                      .format(AppDefaults.INCIDENT_DATE_FORMAT)
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            incidentProps[def?.sortableField] = incident?.incidentDateTime;
            break;

          case 'TAGS':
            let tagsList = '';

            if (Array.isArray(incident?.tags) && incident.tags.length > 0) {
              let i = 0,
                tagsLength = incident.tags.length,
                lookupTags = [],
                incidentTag,
                tagName;

              if (Array.isArray(currentTags) && currentTags.length > 0) {
                lookupTags = [...currentTags];
              } else if (Array.isArray(tags) && tags.length > 0) {
                lookupTags = [...tags];
              }

              // We're using a while-loop because it's more performant
              // than a for-loop, for-of-loop, .forEach)_ or .map()
              while (i < tagsLength) {
                incidentTag = incident.tags[i];

                if (lookupTags.length > 0) {
                  tagName = lookupTags.find(
                    (tag) =>
                      tag?.tagId === incidentTag || tag?.name === incidentTag,
                  );

                  if (tagName) {
                    if (!tagsList) {
                      tagsList += tagName?.name ? `${tagName.name}` : '';
                    } else {
                      tagsList += tagName?.name ? `, ${tagName.name}` : '';
                    }
                  }
                }

                i++;
              }
            }

            incidentProps[def?.field] = tagsList;
            incidentProps[def?.sortableField] = tagsList;

            break;

          case 'ACTION':
            incidentProps[def?.field] = (
              <div
                key={`action-field-${incident?.incidentId}`}
                className="action-field"
              >
                <ContextMenu
                  menuId={incident?.incidentId}
                  menuItems={menuItems}
                  customData={{ incidentId: incident.incidentId }}
                />
              </div>
            );
            incidentProps[def?.sortableField] = null;
            break;

          default:
            if (def?.field in incident) {
              incidentProps[def?.field] = incident[def?.field];
              incidentProps[def?.sortableField] = incident[def?.field];
            }
        }
      });

      incidentProps.rowId = incident.incidentId;
      rowData.push(incidentProps);
      incidentProps = {};
    });

    return rowData;
  };

  return (
    <div className="App manage-incidents">
      <Header />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              {showLoader ? (
                <div
                  className="position-absolute top-50"
                  style={{ left: '47%' }}
                >
                  <SiteSpinner height="50px" width="50px" />
                  <div className="mt-2 text-dark">{constants.LOADING}</div>
                </div>
              ) : (
                <div className="page-header mt-4 mb-5">
                  <Row xs={1} md={2} className="g-4">
                    <Col md={3} lg={3} xl={3} xs={12} className="left-align">
                      <div className="page-title">
                        <div className="incidents-list-title">
                          {constants.INCIDENTS_PAGE_TITLE}
                          <span className="incidents-total">
                            {Utils.replaceStringValues(
                              constants.INCIDENTS_NUM_OF_INCIDENTS_RESULTS,
                              '$numOfIncidents',
                              `${numOfIncidents}`,
                            )}
                            {numOfIncidents > 1 ? 's' : ''}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={9} lg={9} xl={9} xs={12} className="text-end">
                      <div className="incidents-filters">
                        <LocationSelector
                          variant="default"
                          minWidth={'178px'}
                          persist={false}
                          callback={(locationId) => {
                            if (!locationId) return;

                            filterIncidentsByLocationId(locationId);
                          }}
                        />
                        {currentUserPolicies?.create_update_delete_tag && (
                          <Button
                            variant="outline-primary"
                            size="md"
                            className="manage-tags"
                            onClick={() => {
                              setShowManageTagsModal(true);
                            }}
                          >
                            <span className="manage-tags-button-label">
                              {constants.INCIDENTS_BUTTON_MANAGE_TAGS_LABEL}
                            </span>
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          size="md"
                          className="new-incidents"
                          onClick={() => {
                            setShowNewIncidentModal(true);
                          }}
                        >
                          <GoPlus
                            className="new-incidents-plus"
                            size={'1.7em'}
                          />
                          <span className="new-incidents-button-label">
                            {constants.INCIDENTS_BUTTON_NEW_INCIDENT_LABEL}
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      {hasData ? (
                        <div className="incidents-table-container">
                          {Array.isArray(rowData) && (
                            <LazyLoadComponent>
                              <ListTable
                                colsDef={columnDefs}
                                colSorting={true}
                                rowData={rowData}
                                statusMap={statusMap}
                                cssClasses="incidents-table"
                                rowClassName={
                                  !hasViewEditAccess ? 'row-item-disabled' : ''
                                }
                                rowClickDisabled={!hasViewEditAccess}
                                rowClickHandler={(id) => {
                                  if (hasViewEditAccess) {
                                    navigate(
                                      `/incidents/details.html?incidentId=${id}`,
                                    );
                                  }
                                }}
                                specialDataField="STATUS"
                                specialColumnType={
                                  localConstants.INCIDENT_STATUS
                                }
                                sortableField="sortableField"
                                noDataContent={
                                  <Box className="no-incidents-container">
                                    <HiOutlineFolderOpen
                                      size={32}
                                      className="folder-icon"
                                      style={{
                                        height: '32px',
                                        width: '32px',
                                        color: '#111418',
                                        fontWeight: 400,
                                        flexShrink: 0,
                                        marginTop: '14px',
                                      }}
                                    />
                                    <p className="no-incident-alert">
                                      {
                                        constants.INCIDENTS_DETAILS_NO_MATCHING_INCIDENTS_TEXT
                                      }
                                    </p>
                                  </Box>
                                }
                              />
                            </LazyLoadComponent>
                          )}
                        </div>
                      ) : (
                        <Box className="no-incidents-container">
                          <HiOutlineFolderOpen
                            size={32}
                            className="folder-icon"
                          />
                          <p className="no-incident-alert">
                            {constants.INCIDENTS_DETAILS_NO_INCIDENTS_TEXT}
                          </p>
                        </Box>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
      {/* Modals */}
      {/* Filter Incidents */}
      <SiteModal
        key={'filter-incident-modal'}
        classes="filter-incident-modal"
        wrapperClass="filter-incident-modal-wrapper"
        modalTitle={constants.INCIDENTS_MODAL_STATUS_FILTER_TITLE}
        showModal={showFilterIncidentsModal}
        hideModal={() => {
          setShowFilterIncidentsModal(false);
          setInitialLoad(false);
        }}
        modalFooter={
          <div className="mb-1 filter-incident-button-wrapper">
            <PrimaryButton
              className="filter-incident-save-btn"
              type="submit"
              onClick={(e) => {
                e?.preventDefault();
                applyStatusFilters();
                setShowFilterIncidentsModal(false);
                setInitialLoad(false);
              }}
            >
              {constants.INCIDENTS_MODAL_STATUS_FILTER_APPLY_BUTTON_LABEL}
            </PrimaryButton>
          </div>
        }
      >
        <StatusFilters
          statusFilters={statusFilters}
          initialLoad={initialLoad}
          updateFilters={setStatusFilters}
        />
      </SiteModal>
      {/* New Incident */}
      <SiteModal
        key={'new-incident-modal'}
        classes="new-incident-modal"
        wrapperClass="new-incident-modal-wrapper"
        modalTitle={constants.INCIDENTS_MODAL_NEW_INCIDENT_TITLE}
        showModal={showNewIncidentModal}
        hideModal={() => {
          setShowButtonLoader(false);
          setIsNewIncidentFormComplete(false);
          setShowNewIncidentModal(false);
          setSubmitNewIncidentForm(false);
        }}
        modalFooter={
          <div className="new-incident-button-wrapper">
            <PrimaryButton
              className="save-btn"
              loader={showButtonLoader}
              type="submit"
              disabled={!isNewIncidentFormComplete}
              onClick={(e) => {
                e?.preventDefault();
                setSubmitNewIncidentForm(true);
                setShowButtonLoader(true);
              }}
            >
              {constants.INCIDENTS_MODAL_NEW_INCIDENT_CREATE_BUTTON_LABEL}
            </PrimaryButton>
          </div>
        }
      >
        <NewIncident
          shouldSubmitForm={submitNewIncidentForm}
          openModal={(showModal) => {
            setShowNewIncidentModal(showModal);
          }}
          enableButtonLoader={(enable) => setShowButtonLoader(enable)}
          setFormComplete={setIsNewIncidentFormComplete}
          submitCallback={(data) => {
            refreshIncidents(data);
          }}
          setUserMsg={(e) => {
            setUserMsg(e);
          }}
          setShowToast={(e) => {
            setShowToast(e);
          }}
          setSubmitNewIncidentForm={(e) => {
            setSubmitNewIncidentForm(e);
          }}
          errorHandle={() => {
            setSubmitNewIncidentForm(false);
            setIsNewIncidentFormComplete(false);
            setTimeout(() => {
              setShowToast(false);
            }, 5000);
          }}
        />
      </SiteModal>
      {/* Manage Tags */}
      <SiteModal
        key={'incident-manage-tags-modal'}
        classes="manage-tags-modal"
        wrapperClass="manage-tags-modal-wrapper"
        modalTitle={constants.INCIDENTS_BUTTON_MANAGE_TAGS_LABEL}
        showModal={showManageTagsModal}
        hideModal={() => {
          setAreTagsModified(false);
          setShowManageTagsModal(false);
          setManageTagsUserMsg('');
          setShowManageTagsToast(false);
        }}
        modalFooter={
          <div className="px-0 mx-0 manage-tags-button-group">
            <div className="mb-3 px-0 manage-tags-button-wrapper">
              <PrimaryButton
                className={`manage-tags-save-btn${
                  !areTagsModified ? ' disabled' : ''
                }`}
                loader={showButtonLoader}
                type="submit"
                disabled={!areTagsModified}
                onClick={(e) => {
                  e?.preventDefault();
                  setShouldSaveTags(true);
                }}
              >
                {constants.INCIDENTS_MANAGE_TAGS_MODAL_SAVE_BUTTON_LABEL}
              </PrimaryButton>
            </div>
            <div className="px-0 manage-tags-button-wrapper">
              <Button
                className="manage-tags-cancel-btn"
                variant="outline-secondary"
                onClick={() => {
                  setShowManageTagsModal(false);
                }}
              >
                {constants.INCIDENTS_MANAGE_TAGS_MODAL_CANCEL_BUTTON_LABEL}
              </Button>
            </div>
          </div>
        }
        showToast={showManageTagsToast}
        userMsg={manageTagsUserMsg}
      >
        <ManageTags
          openModal={(showModal) => {
            refreshIncidents();
            setShowManageTagsModal(showModal);
          }}
          clipCategory="IM"
          setAreTagsModified={setAreTagsModified}
          shouldSaveTags={shouldSaveTags}
          setShowButtonLoader={setShowButtonLoader}
          callback={setShouldSaveTags}
          setUserMsg={setManageTagsUserMsg}
          setShowToast={setShowManageTagsToast}
        />
      </SiteModal>
    </div>
  );
};

export default trackWindowScroll(ManageIncidents);
